import {
  SAVETOKEN,
  GETAVAILABLEACCOUNT,
  GETUSER,
  GETAVAILABLEACCOUNTS,
  SAVEVIEW,
  SHOWUPDATELOADING,
  SAVECRITERIA,
  TABLEROWSELECTED,
  SELECTEDDESTHIGHLIGHTEDOND,
  SELECTEDORIGINHIGHLIGHTEDOND,
  SELECTEDAIRLINEHIGHLIGHTED,
  SELECTEDDESTHIGHLIGHTEDONDUPDATE,
  SELECTEDORIGINHIGHLIGHTEDONDUPDATE,
  SELECTEDAIRLINEHIGHLIGHTEDUPDATE,
} from "./app.types";
import apiFetch from "../../main";

export const saveToken = (token) => {
  return async (dispatch) => {
    //nameless functions
    // Initial action dispatched

    // Return promise with success and failure actions

    return dispatch({
      type: SAVETOKEN,
      payLoad: token,
    });
  };
};

export const getAvailableAccount = () => {
  return async (dispatch) => {
    apiFetch({
      url: "/booking_analysis_accounts/active",
      dataWS: true,
    }).then((res) => {
      return dispatch({
        type: GETAVAILABLEACCOUNT,
        payLoad: res.booking_analysis_account,
      });
    });
  };
};

export const getAvailableAccounts = () => {
  return async (dispatch) => {
    apiFetch({
      url: "/booking_analysis_accounts",
      dataWS: true,
    }).then((res) => {
      return dispatch({
        type: GETAVAILABLEACCOUNTS,
        payLoad: res.booking_analysis_accounts,
      });
    });
  };
};

export const changeAccount = (user) => {
  return async (dispatch) => {
    apiFetch({
      url: "/users/me",
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user }),
    }).then((res) => {  return dispatch({
      type: GETUSER,
      payLoad: res,
    }); });
  };
};

export const saveView = (view) => {
  return async (dispatch) => {
    return dispatch({
      type: SAVEVIEW,
      payLoad: view,
    });
  };
};
export const setUpdateLoading = (bool) => {
  return async (dispatch) => {
    return dispatch({
      type: SHOWUPDATELOADING,
      payLoad: bool,
    });
  };
};
export const saveCriteria = (criteria) => {
  return async (dispatch) => {
    return dispatch({
      type: SAVECRITERIA,
      payLoad: criteria,
    });
  };
};
export const saveRowSelected = (rowSelected) => {
  return async (dispatch) => {
    return dispatch({
      type: TABLEROWSELECTED,
      payLoad: rowSelected,
    });
  };
};
export const saveHighlightedAirline = (airline) => {
  return async (dispatch) => {
    return dispatch({
      type: SELECTEDAIRLINEHIGHLIGHTED,
      payLoad: airline,
    });
  };
};
export const saveHighlightedOrigin = (from) => {
  return async (dispatch) => {
    return dispatch({
      type: SELECTEDORIGINHIGHLIGHTEDOND,
      payLoad: from,
    });
  };
};
export const saveHighlightedDest = (to) => {
  return async (dispatch) => {
    return dispatch({
      type: SELECTEDDESTHIGHLIGHTEDOND,
      payLoad: to,
    });
  };
};
export const saveHighlightedAirlineUpdate = (airline) => {
  return async (dispatch) => {
    return dispatch({
      type: SELECTEDAIRLINEHIGHLIGHTEDUPDATE,
      payLoad: airline,
    });
  };
};
export const saveHighlightedOriginUpdate = (from) => {
  return async (dispatch) => {
    return dispatch({
      type: SELECTEDORIGINHIGHLIGHTEDONDUPDATE,
      payLoad: from,
    });
  };
};
export const saveHighlightedDestUpdate = (to) => {
  return async (dispatch) => {
    return dispatch({
      type: SELECTEDDESTHIGHLIGHTEDONDUPDATE,
      payLoad: to,
    });
  };
};
