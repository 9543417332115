// eslint-disable-next-line
import logo from "../../assets/images/logo.png";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { logout } from "@vana/ami-ui-authentication";
import { changeAccount } from "../../redux/App/app.actions";
import Tabs from "../tabs/tabs";
import "../../amadeus.css";
import "./header.css";
import apiFetch from "../../main";

function Header(props) {
  const [header, setHeader] = useState({
    isAccountClicked: false,
  });

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideAccounts, true);
  }, []);
  const handleClickOutsideAccounts = (e) => {
    const accountsPopup = document.getElementById("accountsPopup");
    const accountsButton = document.getElementById("accountsButton");

    if (
      accountsPopup &&
      !accountsPopup.contains(e.target) &&
      !accountsButton.contains(e.target)
    ) {
      setHeader({
        ...header,
        isAccountClicked: false,
      });
    }
  };

  const { email, activeAccount, availableAccounts } = props;
  return (
    <>
      <div className="appBackground">
        <div className="blueBackground">
          <div className="container">
            <div className="grayHeaderHeight appBar">
              <img
                alt="Amadeus"
                height="36"
                src={logo}
                title="Amadeus Business Intelligence"
                width="128"
              />
              <div className="userMenuDropdown dropdown open">
                <button
                  className="accountName dropdown-toggle"
                  id="accountsButton"
                  onClick={(ev) =>
                    setHeader({
                      ...header,
                      isAccountClicked: !header.isAccountClicked,
                    })
                  }
                >
                  {email}
                  {`(${activeAccount})`}
                  <span className="caret"></span>
                </button>
                {header.isAccountClicked === true && (
                  <div
                    id="dropdown-menu"
                    className="dropdown-menu userMenuDropdownMenu pull-right"
                  >
                    <div className="userMenuDropdownPanel" id="accountsPopup">
                      <div className="accountField">
                        <label className="control-label" for="account">
                          Account
                        </label>
                        <select
                          id="account"
                          className="form-control"
                          onChange={(ev) => changeAccount(ev.target.value)}
                          value={activeAccount}
                        >
                          {header &&
                            availableAccounts&&availableAccounts.map((account, a) => {
                              return (
                                <option value={account.name} key={a}>
                                  {account.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                      <div>
                        <a onClick={() => logout()}>Log-out</a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Tabs activeAccount={ activeAccount} />
      </div>
    </>
  );

  function changeAccount(newAccount) {
    let user = props.userSettings.user;
    let bookingAnalysisAccountPreference = user.preferences.find(
      (x) => x.key === "booking_analysis_account"
    );

    if (!bookingAnalysisAccountPreference) {
      bookingAnalysisAccountPreference = {
        key: "booking_analysis_account",
        type: "id",
      };
      user.preferences.push(bookingAnalysisAccountPreference);
    }
    bookingAnalysisAccountPreference.value = newAccount;

    apiFetch({
      url: '/users/me',
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ user }),
    }).then((res) => {
      if (res) {
        setTimeout(() => {
          props.initializeUserInfo(true);
        },100);
      }
    });
  }
}

const mapStateToProps = (state) => {
  return {
    userSettings: state.app.userSettings,
    user:state.app.user,
    availableAccount: state.app.availableAccount,
    availableAccounts: state.app.availableAccounts,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeAccount: (user) => dispatch(changeAccount(user)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
