export const SAVETOKEN = "saveToken";
export const GETUSER = 'GETUSER';
export const GETAVAILABLEACCOUNT = "GETAVAILABLEACCOUNT";
export const GETAVAILABLEACCOUNTS = "GETAVAILABLEACCOUNTS";
export const SAVEVIEW = "SAVEVIEW";
export const SAVESELECTEDDATE = "SAVESELECTEDDATE";
export const SHOWUPDATELOADING = "SHOWUPDATELOADING";
export const SAVECRITERIA = "SAVECRITERIA";
export const TABLEROWSELECTED = "TABLEROWSELECTED";
export const SELECTEDORIGINHIGHLIGHTEDOND = "SELECTEDORIGINHIGHLIGHTEDOND";
export const SELECTEDDESTHIGHLIGHTEDOND = "SELECTEDDESTHIGHLIGHTEDOND";
export const SELECTEDAIRLINEHIGHLIGHTED = "SELECTEDAIRLINEHIGHLIGHTED";
export const SELECTEDORIGINHIGHLIGHTEDONDUPDATE =
  "SELECTEDORIGINHIGHLIGHTEDONDUPDATE";
export const SELECTEDDESTHIGHLIGHTEDONDUPDATE =
  "SELECTEDDESTHIGHLIGHTEDONDUPDATE";
export const SELECTEDAIRLINEHIGHLIGHTEDUPDATE =
  "SELECTEDAIRLINEHIGHLIGHTEDUPDATE";
