import {
  SAVETOKEN,
  GETAVAILABLEACCOUNTS,
  GETAVAILABLEACCOUNT,
  GETUSER,
  SAVEVIEW,
  SHOWUPDATELOADING,
  SAVECRITERIA,
  TABLEROWSELECTED,
  SELECTEDDESTHIGHLIGHTEDOND,
  SELECTEDORIGINHIGHLIGHTEDOND,
  SELECTEDAIRLINEHIGHLIGHTED,
  SELECTEDDESTHIGHLIGHTEDONDUPDATE,
  SELECTEDORIGINHIGHLIGHTEDONDUPDATE,
  SELECTEDAIRLINEHIGHLIGHTEDUPDATE,
} from "./app.types";

const INITIAL_STATE = {
  userSettings: undefined,
  user:undefined,
  availableAccount: undefined,
  availableAccounts: undefined,
  view: "onds",
  updateLoading: false,
  criteria: {
    selectedOrigin: "",
    selectedDestination: "",
    selectedDate: {
      year: String(new Date().getFullYear()),
      month: String(new Date().getMonth() + 1),
      specificDate: "",
      week: "",
      quarter: "",
    },
    period: "fullPeriod",
    dateType: "booking",
    periodComparisonType: "samePeriodPreviousYear",
    pointOfSale: "",
    market: "",
    marketingCarrier: "",
    highlightedmarketingCarrier: undefined,
    highlightedOnd: undefined,
    cabinClass: "",
    sortBy: "travel_agency",
  },
  rowSelected: "",
  highlightedDest: "",
  highlightedOrigin: "",
  highlightedAirline: "",
  highlightedDestUpdate: "",
  highlightedOriginUpdate: "",
  highlightedAirlineUpdate: "",
};

const app = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SAVETOKEN:
      return {
        ...state,
        userSettings: action.payLoad,
      };
     case GETUSER:
      return {
        ...state,
        user: action.payLoad,
      };

    case GETAVAILABLEACCOUNT:
      return {
        ...state,
        availableAccount: action.payLoad,
      };
    case GETAVAILABLEACCOUNTS:
      return {
        ...state,
        availableAccounts: action.payLoad,
      };
    case SAVEVIEW:
      return {
        ...state,
        view: action.payLoad,
      };
    case SHOWUPDATELOADING:
      return {
        ...state,
        updateLoading: action.payLoad,
      };
    case SAVECRITERIA:
      return {
        ...state,
        criteria: action.payLoad,
      };
    case TABLEROWSELECTED:
      return {
        ...state,
        rowSelected: action.payLoad,
      };
    case SELECTEDDESTHIGHLIGHTEDOND:
      return {
        ...state,
        highlightedDest: action.payLoad,
      };
    case SELECTEDORIGINHIGHLIGHTEDOND:
      return {
        ...state,
        highlightedOrigin: action.payLoad,
      };
    case SELECTEDAIRLINEHIGHLIGHTED:
      return {
        ...state,
        highlightedAirline: action.payLoad,
      };
    case SELECTEDDESTHIGHLIGHTEDONDUPDATE:
      return {
        ...state,
        highlightedDestUpdate: action.payLoad,
      };
    case SELECTEDORIGINHIGHLIGHTEDONDUPDATE:
      return {
        ...state,
        highlightedOriginUpdate: action.payLoad,
      };
    case SELECTEDAIRLINEHIGHLIGHTEDUPDATE:
      return {
        ...state,
        highlightedAirlineUpdate: action.payLoad,
      };

    default:
      return state;
  }
};

export default app;
