import { dataServicesUrl, env, servicesUrl } from ".";
import store from "./redux/store";

export default async function apiFetch({
  url,
  headers = {},
  dataWS = false,
  ...otherParams
} = {}) {
  let wServiceUrl = dataWS ? dataServicesUrl : servicesUrl;

  try {
    const res = await fetch(`${wServiceUrl}${url}${encodeQueryString(otherParams.params || {})}`,{
      method: "GET",
      headers: {
        Accept: "application/json",
        ...headers,
        ...(env.legacy === true && {
          Authorization: `Token ${store.getState().app.userSettings.token}`
        }),        
      },
      //Below line code will include cookies for autorization
      ...(env.legacy === false && { credentials: "include" }),
      ...otherParams,
    });
    return await res.json();
  } catch (response) {}
}


function encodeQueryString(params) {
  const keys = Object.keys(params).length>0?Object.keys(params).filter(key=>![undefined,null,'',""].includes(params[key])):[]
  let parameters=""
  if (keys.length > 0) {

    let p = []
    for (let i = 0; i < keys.length; i++) { 
      let k = keys[i].concat("=").concat(params[keys[i]])
      p.push(i===keys.length-1?k:k.concat("&"))
    }
   
   parameters="?".concat(p.join(''))
   
  } else { }
  
  return parameters
 
}